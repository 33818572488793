import React from "react"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import Head from "../components/Head"

// Image
import BackBuch2JPG from "../static/backbuch2.jpg"

class BackBuch2 extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Das Backbuch - Band II</h1>
                <br />
                <p class="MsoNormal">
                  {" "}
                  <img
                    src={BackBuch2JPG}
                    border="0"
                    height="365"
                    width="400"
                    alt=""
                  />
                  <br />
                </p>
              </div>
              <div class="item">
                <h1>Katharinas Backbuch Band 2 ist leider vergriffen</h1>
                <br />
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default BackBuch2
